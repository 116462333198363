import React, { useState } from "react";
import {
   Button,
   TextField,
   Paper,
   Typography,
   Snackbar,
   CircularProgress,
   Table,
   TableBody,
   TableCell,
   TableRow,
   Checkbox,
   Alert,
   Box,
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   FormControlLabel,
} from "@mui/material";
import { useAppSelector } from "../app/hooks";
import PageTemplate from "../components/PageTemplate";

const Home = () => {
   const BASE_URL = "https://tcs.payTiptap.com";
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "es" ? false : true;
   const [email, setEmail] = useState("");
   const [emailError, setEmailError] = useState(false);
   const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [results, setResults] = useState([]);
   const [loading, setLoading] = useState(false);
   const [selected, setSelected] = useState([]);
   const [total, setTotal] = useState(0);
   const [snackbar2Open, setSnackbar2Open] = useState(false);
   const [snackbar3Open, setSnackbar3Open] = useState(false);

   const [dialogOpen, setDialogOpen] = useState(false);
   const [accepted, setAccepted] = useState(false);

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
      setEmailError(false);
   };

   const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
   };

   const handleSubmitEmail = async (event) => {
      event.preventDefault();
      setSnackbar3Open(false);

      if (!validateEmail(email)) {
         setEmailError(true);
         setSnackbarOpen(true);
         return;
      }

      setLoading(true); // Start loading

      try {
         const res = await fetch("https://dev.payTiptap.com/api/tcs", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
         });

         if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
         }

         const data = await res.json();
         console.log("Response Data:", data.data);
         if (data.data.length > 0) {
            setResults(data.data);
         } else {
            setSnackbar3Open(true);
         }
      } catch (error) {
         console.error("Error:", error);
      } finally {
         setLoading(false); // End loading
      }
   };

   const handleSnackbarClose = () => setSnackbarOpen(false);
   const handleSnackbar2Close = () => setSnackbar2Open(false);
   const handleSnackbar3Close = () => setSnackbar3Open(false);

   const handleCheckboxChange = (event, row) => {
      const isChecked = event.target.checked;
      if (isChecked) {
         setSelected([...selected, row]);
         setTotal((prevTotal) => prevTotal + row.montocuotamensual);
      } else {
         setSelected(selected.filter((item) => item !== row));
         setTotal((prevTotal) => prevTotal - row.montocuotamensual);
      }
   };

   const ls = () => {
      // create alphanum string 30 chars long
      let s = "";
      for (let i = 0; i < 40; i++) {
         s += String.fromCharCode(Math.floor(Math.random() * 26) + 97);
      }
      return s;
   };

   const handlePayNow = () => {
      if (total < 0.001) {
         setSnackbar2Open(true);
      } else {
         setDialogOpen(true); // Open the terms and policies dialog
      }
   };

   const handleDialogClose = () => {
      setDialogOpen(false);
   };

   const handleAcceptAndProceed = () => {
      if (accepted) {
         // Proceed with payment
         console.log("Proceeding to payment with total:", total);
         //window.location.href = `/cc/?con=${ls()}&email=${email}&ex=${ls()}&total=${total}&fin=${ls()}`;
         window.location.href = `${BASE_URL}/cc/?EN=${EN}&email=${email}&total=${total}`;
         setDialogOpen(false); // Close the dialog
      }
   };

   return (
      <PageTemplate>
         <Box display='flex' justifyContent='center' alignItems='center' px={1}>
            <Paper
               elevation={5}
               style={{
                  minWidth: 320,
                  maxWidth: "calc(100% - 12px)",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
               }}
               sx={{ px: 2, py: 8 }}
            >
               <Box marginBottom={2}>
                  <img src='/tcs.png' alt='TSC Logo' width={250} />
               </Box>
               {results.length > 0 ? (
                  <>
                     <Table>
                        <TableBody>
                           {results.map((row, index) => (
                              <TableRow key={index}>
                                 <TableCell padding='checkbox'>
                                    <Checkbox
                                       checked={selected.includes(row)}
                                       onChange={(event) =>
                                          handleCheckboxChange(event, row)
                                       }
                                    />
                                 </TableCell>
                                 <TableCell>{row.nombre}</TableCell>
                                 <TableCell align='right'>
                                    {row.montocuotamensual.toLocaleString(
                                       "en-US",
                                       {
                                          style: "currency",
                                          currency: "USD",
                                       }
                                    )}
                                 </TableCell>
                              </TableRow>
                           ))}
                           <TableRow>
                              <TableCell colSpan={2} align='right'>
                                 <Typography variant='h6'>Total:</Typography>
                              </TableCell>
                              <TableCell align='right'>
                                 <Typography variant='h6'>
                                    {total.toLocaleString("en-US", {
                                       style: "currency",
                                       currency: "USD",
                                    })}
                                 </Typography>
                              </TableCell>
                           </TableRow>
                           <TableRow>
                              <TableCell colSpan={3} align='center'>
                                 <Button
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                    onClick={handlePayNow}
                                 >
                                    {EN ? "Pay Now" : "Pagar Ahora"}
                                 </Button>
                              </TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </>
               ) : (
                  <>
                     <Typography variant='h6' gutterBottom>
                        {EN ? "Email" : "Correo Electrónico"}
                     </Typography>
                     <form onSubmit={handleSubmitEmail}>
                        <TextField
                           label={EN ? "Email" : "Correo Electrónico"}
                           value={email}
                           onChange={handleEmailChange}
                           error={emailError}
                           fullWidth
                           margin='normal'
                        />
                        <Button
                           type='submit'
                           variant='contained'
                           color='primary'
                           fullWidth
                           style={{ marginTop: 16 }}
                           disabled={loading}
                           startIcon={loading && <CircularProgress size={20} />}
                        >
                           {loading
                              ? EN
                                 ? "Loading..."
                                 : "Cargando..."
                              : EN
                              ? "Submit"
                              : "Enviar"}
                        </Button>
                     </form>
                  </>
               )}
            </Paper>
            <Snackbar
               open={snackbarOpen}
               autoHideDuration={6000}
               onClose={handleSnackbarClose}
            >
               <Alert onClose={handleSnackbarClose} severity='error'>
                  {EN ? "Invalid email" : "Correo electrónico no válido"}
               </Alert>
            </Snackbar>
            <Snackbar
               open={snackbar2Open}
               autoHideDuration={6000}
               onClose={handleSnackbar2Close}
            >
               <Alert onClose={handleSnackbar2Close} severity='error'>
                  {EN ? "Select a box" : "Seleccione una casilla"}
               </Alert>
            </Snackbar>
            <Snackbar
               open={snackbar3Open}
               autoHideDuration={6000}
               onClose={handleSnackbar3Close}
            >
               <Alert onClose={handleSnackbar3Close} severity='error'>
                  {EN ? "Email not found" : "Correo electrónico no encontrado"}
               </Alert>
            </Snackbar>
         </Box>

         <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>
               {EN
                  ? "Acceptance of Terms and Policies"
                  : "Aceptación de Términos y Políticas"}
            </DialogTitle>
            <DialogContent>
               <DialogContentText>
                  {EN
                     ? "Please read and accept the terms and policies before proceeding with the payment."
                     : "Por favor, lea y acepte los términos y políticas antes de proceder con el pago."}
               </DialogContentText>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={accepted}
                        onChange={(e) => setAccepted(e.target.checked)}
                     />
                  }
                  label={
                     EN
                        ? "I accept the terms and policies"
                        : "Acepto los términos y políticas"
                  }
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={handleDialogClose}>
                  {EN ? "Cancel" : "Cancelar"}
               </Button>
               <Button
                  onClick={handleAcceptAndProceed}
                  color='primary'
                  variant='contained'
                  disabled={!accepted}
               >
                  {EN ? "Proceed to Pay" : "Proceder al Pago"}
               </Button>
            </DialogActions>
         </Dialog>
      </PageTemplate>
   );
};

export default Home;
