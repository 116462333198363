import * as React from "react";
import {
   Grid,
   Container,
   Box,
   Link,
   FormControl,
   MenuItem,
   Select,
} from "@mui/material";
import { setToggleLang } from "../features/session/sessionSlice";

import "../App.css";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Lang from "./Lang";
import Footer from "./Footer";

const PageTemplate = ({ children }) => {
   const session = useAppSelector((state) => state.session);
   const EN = session.lang === "en" ? true : false;
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
         }}
      >
         {/* Top Row */}
         <Grid
            container
            style={{
               width: "100%",
               height: "50px",
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               padding: "0 7px",
            }}
         >
            <Grid item xs={6} sm={6}>
               {/* Content on the left */}
               <Box>{/* <Logo centered={false} /> */}</Box>
            </Grid>
            <Grid
               item
               xs={6}
               sm={6}
               sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "flex-end" },
               }}
            >
               {/* Content on the right */}
               <Lang />
            </Grid>
         </Grid>

         {/* Middle Row */}
         <Container
            maxWidth='md'
            sx={{
               flex: "1 0 auto", // Allows the middle row to grow and take available space
               justifyContent: "center",
               alignItems: "center",
               textAlign: "center",
               paddingTop: "16px",
               paddingBottom: "16px",
            }}
         >
            {/* Centered Content */}
            <Box>{children}</Box>
         </Container>

         {/* Bottom Row */}
         <Grid
            container
            sx={{
               padding: 1,
               mt: "auto", // Pushes the bottom row to the bottom if there's space
            }}
         >
            <Grid item xs={12}>
               {/* Content on the right */}

               <Footer />
            </Grid>
         </Grid>
      </Box>
   );
}; // End of Template

export default PageTemplate;
